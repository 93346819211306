<template>
  <div>
    <h2>Capacité thermique massive d'un métal: données + calculs</h2>
    <v-form>
      <!-- Data Table -->
      <h3 class="pt-6 pb-4">Tableau de données</h3>
      <v-textarea
        v-model="inputs.data_table_caption"
        label="Légende"
        outlined
        rows="1"
        class="mb-n3"
        placeholder="Rédigez une légende pour le tableau des données ici."
      />
      <v-simple-table>
        <thead>
          <tr>
            <td>&nbsp;</td>
            <td style="font-weight: bold; text-align: center">Unités</td>
            <td style="font-weight: bold; text-align: center">Essai 1</td>
            <td style="font-weight: bold; text-align: center">Essai 2</td>
          </tr>
        </thead>
        <tr>
          <th style="text-align: left">Identité</th>
          <td>&nbsp;</td>
          <td v-for="i in 2" :key="`known-${i}`">
            <v-select
              v-model="inputs[`trial${i}_identity`]"
              :items="['Zn', 'Cu']"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex class="no-text-transform" :content="item" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item" />
              </template>
            </v-select>
          </td>
        </tr>
        <tr v-for="{label, key, units: rowUnits} in dataTableRows" :key="key">
          <th style="text-align: left">
            <stemble-latex :content="label" />
          </th>
          <td>
            <v-select
              v-if="!!rowUnits"
              v-model="inputs[`${key}_units`]"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
            <span v-else>&nbsp;</span>
          </td>
          <td v-for="i in 2" :key="`known-${key}-${i}`">
            <calculation-input v-model="inputs[`trial${i}_${key}`]" class="centered-input" />
          </td>
        </tr>
      </v-simple-table>

      <!-- Data Upload -->
      <h3 class="pt-8 pb-3">Téléversement de graphique</h3>

      <p class="mb-2">
        Dans cette expérience, vous recueillez des données de température en fonction du temps.
        Tracez vos données de température en fonction du temps (pour compléter votre analyse, vous
        devez tracer les données pour les deux essais, mais nous vous demanderons seulement de
        téléverser le graphique pour l'un des deux essais).
      </p>

      <p class="mb-2">
        Assurez-vous que votre graphique ait un titre approprié et une légende de figure, et que
        vous placiez les données correctes sur les axes correspondants. Étiquetez vos axes et
        indiquez les unités là où cela est nécessaire. Référez-vous à la Figure 2.1 dans la section
        Graphiques du protocole expérimental et tracez la ligne verticale au moment du mélange.
        Ensuite, extrapolez jusqu'au moment du mélange à partir de votre température finale
        stabilisée (elle peut être horizontale ou inclinée en fonction de vos données).
      </p>

      <p class="mb-2">
        Vous avez effectué 2 essais. Téléversez le graphique de l'un des deux en utilisant le champ
        de saisie ci-dessous. Votre graphique doit être clairement titré et au format PDF.
      </p>

      <p class="mb-5">
        <v-file-input v-model="attachments" multiple />
      </p>

      <!-- Calculations -->
      <h3 class="pt-4 pb-3">Calculations</h3>

      <v-textarea
        v-model="inputs.calculations_table_caption"
        label="Légende"
        outlined
        rows="1"
        class="mb-n3"
        placeholder="Rédigez une légende pour le tableau des valeurs calculées ici"
      />
      <v-simple-table>
        <thead>
          <tr>
            <td>&nbsp;</td>
            <td style="font-weight: bold; text-align: center">Unités</td>
            <td style="font-weight: bold; text-align: center">Essai 1</td>
            <td style="font-weight: bold; text-align: center">Essai 2</td>
          </tr>
        </thead>
        <tr
          v-for="{label, key, units: rowUnits, type: inputType} in calculationTableRows"
          :key="key"
        >
          <th style="text-align: left">
            <stemble-latex :content="label" />
          </th>
          <td>
            <v-select
              v-if="!!rowUnits"
              v-model="inputs[`${key}_units`]"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
            <span v-else>&nbsp;</span>
          </td>
          <td v-for="i in 2" :key="`known-${key}-${i}`">
            <v-textarea
              v-if="inputType === 'string'"
              v-model="inputs[`trial${i}_${key}`]"
              outlined
              solo
              rows="3"
            />
            <calculation-input v-else v-model="inputs[`trial${i}_${key}`]" class="centered-input" />
          </td>
        </tr>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'OttawaLab1ReportTask2Data',
  components: {StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        data_table_caption: null,
        calculations_table_caption: null,

        mass_metal_units: null,
        initial_temp_metal_units: null,
        mass_calorimeter_units: null,
        volume_water_units: null,
        mass_calorimeter_and_water_units: null,
        initial_temp_water_units: null,
        final_temp_mixture_units: null,
        mass_water_units: null,
        delta_temp_water_units: null,
        specific_heat_water_units: null,
        delta_temp_metal_units: null,
        specific_heat_metal_units: null,
        literature_specific_heat_metal_units: null,
        percent_error_specific_heat_metal_units: null,

        trial1_identity: null,
        trial2_identity: null,
        trial1_mass_metal: null,
        trial2_mass_metal: null,
        trial1_initial_temp_metal: null,
        trial2_initial_temp_metal: null,
        trial1_mass_calorimeter: null,
        trial2_mass_calorimeter: null,
        trial1_volume_water: null,
        trial2_volume_water: null,
        trial1_mass_calorimeter_and_water: null,
        trial2_mass_calorimeter_and_water: null,
        trial1_initial_temp_water: null,
        trial2_initial_temp_water: null,
        trial1_final_temp_mixture: null,
        trial2_final_temp_mixture: null,
        trial1_mass_water: null,
        trial2_mass_water: null,
        trial1_delta_temp_water: null,
        trial2_delta_temp_water: null,
        trial1_specific_heat_water: null,
        trial2_specific_heat_water: null,
        trial1_reference_specific_heat_water: null,
        trial2_reference_specific_heat_water: null,
        trial1_delta_temp_metal: null,
        trial2_delta_temp_metal: null,
        trial1_specific_heat_metal: null,
        trial2_specific_heat_metal: null,
        trial1_literature_specific_heat_metal: null,
        trial2_literature_specific_heat_metal: null,
        trial1_reference_specific_heat_metal: null,
        trial2_reference_specific_heat_metal: null,
        trial1_percent_error_specific_heat_metal: null,
        trial2_percent_error_specific_heat_metal: null,
      },
      attachments: [],
      units: [
        {key: 'g', label: '$\\text{g}$'},
        {key: 'C', label: '$^{\\omicron}\\text{C}$'},
        {key: 'mL', label: '$\\text{mL}$'},
        {
          key: 'JGC',
          label: '$\\text{J} \\cdot \\text{g}^{-1} \\cdot {^{\\omicron}\\text{C}}^{-1}$',
        },
        {
          key: 'percent',
          label: '$\\%$',
        },
      ],
      dataTableRows: [
        {label: 'Masse du métal', key: 'mass_metal', units: true},
        {label: 'Température initiale du métal', key: 'initial_temp_metal', units: true},
        {label: 'Masse du calorimètre', key: 'mass_calorimeter', units: true},
        {label: "Volume d'eau", key: 'volume_water', units: true},
        {label: 'Masse (eau + calorimètre)', key: 'mass_calorimeter_and_water', units: true},
        {label: "Température initiale de l'eau", key: 'initial_temp_water', units: true},
        {label: 'Température du mélange', key: 'final_temp_mixture', units: true},
      ],
      calculationTableRows: [
        {label: "Masse d'eau", key: 'mass_water', units: true},
        {label: '$\\Delta T_{eau}$', key: 'delta_temp_water', units: true},
        {label: '$c_{eau}$', key: 'specific_heat_water', units: true},
        {
          label: 'Référence pour $c_{eau}$',
          key: 'reference_specific_heat_water',
          units: false,
          type: 'string',
        },
        {label: '$\\Delta T_{metal}$', key: 'delta_temp_metal', units: true},
        {label: '$c_{metal}$ (expérimentale)', key: 'specific_heat_metal', units: true},
        {label: '$c_{metal}$ (littérature)', key: 'literature_specific_heat_metal', units: true},
        {
          label: 'Référence pour $c_{metal}$',
          key: 'reference_specific_heat_metal',
          units: false,
          type: 'string',
        },
        {label: "Pourcentage d'erreur", key: 'percent_error_specific_heat_metal', units: true},
      ],
    };
  },
};
</script>
